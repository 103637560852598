import { map } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// features/example/exampleSlice
// this saved all the work to create actions/reducers/action_types
import { createSlice } from '@reduxjs/toolkit';

const exampleSlice = createSlice({
    name: 'example',
    initialState: {messages: ['hello']},
    reducers: {
        addMessage(state, action) {
            state.messages.push(action.payload);
        },
    },
});

// features/example/ReskuExampleApp.js
// import exampleSlice from 'features/example/exampleSlice';
const ReskuExample = () => {
    const dispatch = useDispatch();
    const { messages } = useSelector((state) => state.example);
    const { actions } = exampleSlice;
    return <div>
        <h1 onClick={() => {
            dispatch(actions.addMessage('hello world'));
        }}>click me</h1>
        <ul>
            {map(messages, (message, i) => {
                return <li key={i}>{message}</li>;
            })}
        </ul>
    </div>;
};

export default function ReskuExampleApp({ attachReducers, ...props }) {
    attachReducers({
        example: exampleSlice.reducer
    });
    return <ReskuExample {...props}/>;
}
